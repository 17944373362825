export const BRAND_SHAPE_CIRCLE = "inset(0 round 50%)";

export const BRAND_SHAPE_HALF_BOTTOM_CIRCLE = "inset(0 round 3% 3% 50% 50%)";
export const BRAND_SHAPE_HALF_TOP_CIRCLE = "inset(0 round 50% 50% 3% 3%)";
export const BRAND_SHAPE_HALF_LEFT_CIRCLE = "inset(0 round 50% 3% 3% 50%)";
export const BRAND_SHAPE_HALF_RIGHT_CIRCLE = "inset(0 round 3% 50% 50% 3%)";

export const BRAND_SHAPE_ROUNDED_TOP_LEFT = "inset(0 round 30% 3% 3% 3%)";
export const BRAND_SHAPE_ROUNDED_TOP_RIGHT = "inset(0 round 3% 30% 3% 3%)";
export const BRAND_SHAPE_ROUNDED_BOTTOM_LEFT = "inset(0 round 3% 3% 3% 30%)";
export const BRAND_SHAPE_ROUNDED_BOTTOM_RIGHT = "inset(0 round 3% 3% 30% 3%)";

export const BRAND_SHAPE_DROP_TOP_LEFT = "inset(0 round 3% 50% 50% 50%)";
export const BRAND_SHAPE_DROP_TOP_RIGHT = "inset(0 round 50% 3% 50% 50%)";
export const BRAND_SHAPE_DROP_BOTTOM_LEFT = "inset(0 round 50% 50% 50% 3%)";
export const BRAND_SHAPE_DROP_BOTTOM_RIGHT = "inset(0 round 50% 50% 3% 50%)";
